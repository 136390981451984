import { Gift } from 'lucide-react';

export function ReferralBanner() {
  return (
    <div className="relative mb-8">
      <div className="relative rounded-md bg-gradient-to-r from-emerald-700 to-emerald-600 p-6 text-center text-white">
        <div className="mb-2 flex items-center justify-center gap-2">
          <Gift className="h-5 w-5" />
          <p className="text-sm font-medium">You&apos;ve been referred!</p>
        </div>
        <h2 className="mb-1 text-center text-2xl font-semibold">
          Get <span className="text-yellow-300">R100 off</span> your company
          registration
        </h2>
        <p className="mx-auto mt-3 max-w-sm text-sm">
          Your friend thinks it&apos;s time you made it official.
        </p>
      </div>
    </div>
  );
}
