import { HandCoins } from 'lucide-react';
import { useHistory } from 'react-router-dom';

const ReferralBanner = () => {
  const history = useHistory();

  return (
    <div
      className="cursor-pointer rounded-md bg-blue-100 px-3 py-2 transition-colors hover:bg-blue-200"
      onClick={() => history.push('/referrals')}
      onKeyDown={(e) => e.key === 'Enter' && history.push('/referrals')}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center gap-3 text-sm text-gray-700">
        <HandCoins size={16} />
        Refer a friend, earn R100.
      </div>
    </div>
  );
};

export default ReferralBanner;
