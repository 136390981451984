import { useCallback, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { menuItems } from './menuItems';
import { analytics } from 'common/analytics/analytics';
import ReferralBanner from 'common/components/ReferralBanner/ReferralBanner';
import UpgradePlanBanner from 'common/components/UpgradePlanBanner/UpgradePlanBanner';
import { logoutUser } from 'common/lib/user';
import { canAccess } from 'modules/subscriptions/utils';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background-color: #fafafa;
  }
  .ant-drawer-body {
    padding: 0;
    background-color: #fafafa;
    .ant-menu {
      background: #fafafa;
    }
  }
`;

const Wrapper = styled.div`
  .ant-menu {
    background: #fafafa;
    border-inline-end: 0px none !important;
  }
  .ant-menu-item {
    color: #434343;
    margin-bottom: 4px !important;
    font-weight: 500;
    :hover {
      color: black;
      background-color: #f0f0f0;
    }
  }
  .ant-menu-item-selected {
    color: black;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f0f0f0;
  }
  .ant-menu-inline .ant-menu-item::after {
    left: 0;
    right: unset;
    border-right: 3px solid black;
  }
  .ant-menu-inline {
    border-right: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0 0px;
    .anticon {
      display: none;
    }
    .ant-menu-title-content {
      margin-left: 0;
    }
  }
`;

export type MainMenuProps = {
  mode: 'desktop' | 'mobile';
};

export const MainMenu = ({ mode }: MainMenuProps) => {
  const history = useHistory();
  const location = useLocation();

  const currentCompany = useSelector(
    (state: any) => state.companies.currentCompany
  );
  const { accessLevel } = currentCompany;

  const showUpgradePlanBanner = accessLevel === 'none';

  const { isBooksEnabled } = currentCompany;

  const [open, setOpen] = useState(false);

  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleClick = ({ path }: { path?: string }) => {
    if (path === '/logout') {
      logoutUser();
      return;
    }
    if (path) {
      analytics.track(`${path} Clicked`, { category: 'Dashboard Menu' });
      history.push(path);
    }
  };

  const getMenuItems = useCallback(() => {
    const hasAccountingAccess = canAccess(currentCompany, 'none');
    const hasBooksAccess = isBooksEnabled;

    // Filter items based on permissions
    let items = [...menuItems];
    if (!hasBooksAccess && !hasAccountingAccess) {
      items = items.filter((item) => item.key !== 'accounting');
    }
    // Hide VAT returns if no VAT number
    if (!currentCompany.vatNum) {
      items = items.map((item) => {
        if (item.key === 'accounting' && item.children) {
          return {
            ...item,
            children: item.children.filter(
              (child) => child.key !== 'vat-returns'
            ),
          };
        }
        return item;
      });
    }

    // Hide account-related items in desktop mode
    if (mode === 'desktop') {
      items = items.filter(
        (item) =>
          !['user-profile', 'billing', 'logout', 'divider', 'plans'].includes(
            item.key
          )
      );
    }

    return items;
  }, [currentCompany, mode, isBooksEnabled]);

  const renderMenu = () => (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={[location.pathname]}
      onClick={({ key }) => handleClick({ path: key })}
      defaultOpenKeys={
        location.pathname.includes('accounting') ? ['accounting'] : []
      }
      items={getMenuItems().map((item) => {
        if (item.type === 'divider') {
          return { type: 'divider', key: item.key };
        }
        return {
          key: item.path || item.key,
          icon: item.icon,
          label: item.label,
          children: item.children?.map((child) => ({
            key: child.path,
            label: child.label,
          })),
        };
      })}
    />
  );

  return (
    <Wrapper>
      {mode === 'desktop' ? (
        renderMenu()
      ) : (
        <div style={{ display: 'flex', width: '100%', textAlign: 'right' }}>
          <Button type="link" onClick={showDrawer} style={{ padding: '0px' }}>
            <MenuOutlined style={{ fontSize: '20px', color: '#000' }} />
          </Button>
          <StyledDrawer
            title="Menu"
            placement="right"
            onClose={onClose}
            open={open}
          >
            {renderMenu()}
            {showUpgradePlanBanner && (
              <div className="mx-2 my-4">
                <UpgradePlanBanner />
              </div>
            )}
            <div className="mx-2 my-4">
              <ReferralBanner />
            </div>
          </StyledDrawer>
        </div>
      )}
    </Wrapper>
  );
};

export default MainMenu;
