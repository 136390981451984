import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MainMenu from './MainMenu';
import ReferralBanner from 'common/components/ReferralBanner/ReferralBanner';
import UpgradePlanBanner from 'common/components/UpgradePlanBanner/UpgradePlanBanner';

const Wrapper = styled.div`
  .dashboard-menu-desktop {
    .ant-menu {
      margin-top: 20px;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }
  .dashboard-menu-mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
    border-bottom: 1px solid #f0f0f0;
  }
`;

const DashboardMenu = () => {
  const currentCompany = useSelector(
    (state: any) => state.companies.currentCompany
  );
  const { accessLevel } = currentCompany;

  const showUpgradePlanBanner = accessLevel === 'none';

  return (
    <Wrapper>
      <div className="dashboard-menu-desktop">
        <MainMenu mode="desktop" />
        {showUpgradePlanBanner && (
          <div className="mx-2 my-4">
            <UpgradePlanBanner />
          </div>
        )}
        <div className="mx-2 my-4">
          <ReferralBanner />
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardMenu;
