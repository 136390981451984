import React from 'react';
import humps from 'humps';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import ErrorModal from './components/ErrorModal';
import { ReferralBanner } from './components/referral-banner';
import SignupForm from './components/SignupForm';
import {
  SignupFormValues,
  CreateUserApiResponse,
  ApiErrorResponse,
  CompanyType,
} from './types';
import { createUserApiPayload } from './utils/createUserApiPayload';
import { generateRedirectUrl } from './utils/generateRedirectUrl';
import { getCompanyTypeBySearchQuery } from './utils/getCompanyTypeBySearchQuery';
import analytics from 'common/analytics/analytics';
import {
  ACCOUNT_SUBMITTED,
  ACCOUNT_CREATED_ERROR,
  ACCOUNT_CREATED,
} from 'common/analytics/events';
import AuthenticationPage from 'common/components/Page/AuthenticationPage/AuthenticationPage';

import { addNewCompany } from 'common/lib/companyServices';
import { createUser, clearUserData } from 'common/lib/user';
import { loadCompanies } from 'common/reducers/companies';
import { loadUser } from 'common/reducers/user';

export const SignupPage = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState<number | null>(null);
  const [companyType, setCompanyType] = React.useState<CompanyType>('');

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['cookie-name']);

  const { gclid } = cookies;
  const searchParams = new URLSearchParams(location.search);
  const hasReferral = searchParams.has('ref');

  React.useEffect(() => {
    const response = getCompanyTypeBySearchQuery(location);
    setCompanyType(response);
  }, [location]);

  const setTokenCookie = (token: string) => {
    const domain = process.env.REACT_APP_DOMAIN || '';
    const expires = new Date(Number(new Date()) + 315360000000);
    setCookie('token', token, {
      domain,
      path: '/',
      expires,
    });
  };

  const handleCreateUser = async (
    values: SignupFormValues
  ): Promise<CreateUserApiResponse> => {
    clearUserData();
    const referralCode = searchParams.get('ref') || undefined;
    const payload = createUserApiPayload(values, gclid, referralCode);
    const formattedPayload = humps.decamelizeKeys(payload);
    // eslint-disable-next-line no-useless-catch
    try {
      let response = await createUser(formattedPayload);
      const { token } = response;
      setTokenCookie(token);
      response = humps.camelizeKeys(response);
      dispatch(
        loadUser({
          ...response,
          phoneNumber: payload.profile.phoneNumber,
        })
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleCreateCompany = async (user: CreateUserApiResponse) => {
    const { token } = user;
    const company = await addNewCompany(token);
    dispatch(loadCompanies([company]));
    const currentCompanyId = company.pk;
    dispatch(
      loadUser({
        ...user,
        currentCompanyId,
      })
    );
  };

  const handleResponseError = (error: ApiErrorResponse) => {
    analytics.track(ACCOUNT_CREATED_ERROR, { error: { ...error } });
    setIsSubmitting(false);
    return setErrorCode(error.status);
  };

  const handleTrackAccountCreated = (userResponse: any, values: any) => {
    const { uuid } = userResponse;
    analytics.identify(uuid);
    analytics.track(ACCOUNT_CREATED, {
      ...userResponse,
      phoneNumber: values.phoneNumber,
      gclid,
    });
  };

  const handleRedirectUser = () => {
    const redirectUrl = generateRedirectUrl(companyType);
    return history.push(redirectUrl);
  };

  const handleSubmit = async (values: SignupFormValues) => {
    setIsSubmitting(true);
    analytics.track(ACCOUNT_SUBMITTED, values);

    let userResponse;
    try {
      userResponse = await handleCreateUser(values);
    } catch (error) {
      // @ts-ignore
      return handleResponseError(error);
    }

    if (companyType === 'new') {
      await handleCreateCompany(userResponse);
    }

    handleTrackAccountCreated(userResponse, values);

    return handleRedirectUser();
  };
  return (
    <>
      <Helmet>
        <title>Start, Run and Grow Your Business | Govchain</title>
        <meta
          name="title"
          content="Start, Run and Grow Your Business | Govchain"
        />
        <meta
          name="description"
          content="Create your account and get started with our service."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Start, Run and Grow Your Business | Govchain"
        />
        <meta
          property="og:description"
          content="Register your company, manage finances, stay compliant, and file taxes—all in one place with Govchain."
        />
        <meta
          property="og:image"
          content="https://www.govchain.co.za/opengraph-image.png"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Start, Run and Grow Your Business | Govchain"
        />
        <meta
          name="twitter:description"
          content="Register your company, manage finances, stay compliant, and file taxes—all in one place with Govchain."
        />
        <meta
          name="twitter:image"
          content="https://www.govchain.co.za/opengraph-image.png"
        />
      </Helmet>
      <AuthenticationPage title="Create Account">
        {hasReferral && <ReferralBanner />}
        <SignupForm onSuccess={handleSubmit} isSubmitting={isSubmitting} />
        <ErrorModal
          errorCode={409}
          visible={typeof errorCode === 'number'}
          onClose={() => setErrorCode(null)}
        />
      </AuthenticationPage>
    </>
  );
};

export default SignupPage;
